import { z } from 'zod';

const color = z
  .string()
  .regex(
    /^#[0-9A-F]{6}$/i,
    'Color must be a valid hex color (Example: #000000)'
  );

export const globalStyleTemplateColorsUpdate = z
  .object({
    primaryColor: color.optional(),
    secondaryColor: color.optional(),
    backgroundColor: color.optional(),
    accentColor: color.optional(),
    additionalColors: z.array(z.object({ color })),
  })
  .superRefine((value, ctx) => {
    const duplicateColorsKeys = new Set<string>();

    const { additionalColors: _, ...colors } = value;
    const colorsEntries = Object.entries(colors);
    colorsEntries.forEach(([key, color], idx) => {
      if (color === '' || duplicateColorsKeys.has(key)) return;

      for (let nextIdx = idx + 1; nextIdx < colorsEntries.length; nextIdx++) {
        const [nextKey, nextColor] = colorsEntries[nextIdx];

        if (color === nextColor) {
          duplicateColorsKeys.add(key);
          duplicateColorsKeys.add(nextKey);

          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [key],
            fatal: false,
            message: 'Color must be unique within the Template Colors',
          });
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [nextKey],
            fatal: false,
            message: 'Color must be unique within the Template Colors',
          });
        }
      }
    });
  });

export const globalStyleSchema = z.object({
  buttonColor: color.nullable(),
  buttonFontColor: color.nullable(),
  headlineFont: z.string().trim(),
  headlineFontColor: color.nullable(),
  headlineFontSize: z.number().nullable(),
  headlineFontSizeUnit: z.string().nullable(),
  hueRotate: z.number().nullable(),
  pageWidth: z.number().nullable(),
  pageWidthUnit: z.string().nullable(),
  paragraphFont: z.string().trim(),
  paragraphFontColor: color.nullable(),
  paragraphFontSize: z.number().nullable(),
  paragraphFontSizeUnit: z.string().nullable(),
  primaryColor: color.nullable(),
  secondaryColor: color.nullable(),
  templateColors: globalStyleTemplateColorsUpdate,
});

export type GlobalStyleSchema = z.infer<typeof globalStyleSchema>;

export type ITemplateColorsUpdateSchema = z.infer<
  typeof globalStyleTemplateColorsUpdate
>;
