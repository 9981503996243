import debounce from 'lodash.debounce';
import {
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import {
  BaseDropdown,
  BaseTooltip,
  useToast,
} from '@gbs-monorepo-packages/common';

import { Card } from '../../../../components/Card';
import {
  type ICoursePage,
  type IDeleteCoursePageInfo,
} from '../../../../contexts/coursePage';
import { type IDragDropHandler } from '../../../../contexts/dragDrop';
import { useCourse } from '../../../../hooks/useCourse';
import { useCoursePages } from '../../../../hooks/useCoursePages';
import { useDragDrop } from '../../../../hooks/useDragDrop';
import {
  type ICoursePageDTO,
  type IHistoryPage,
} from '../../../../services/coursePages';
import { type IMatchTemplateColors } from '../../../../services/templates';
import { generateNegativeTimestamp } from '../../../../utils/generate';
import { DropdownItem } from '../../../Analytics/styles';
import { SearchPageInput } from '../../styles';
import { AddPageModal } from '../AddPageModal';
import { DeletePageModal } from '../DeletePageModal';
import { MatchSvgTemplateColorsModal } from '../MatchSvgTemplateColorsModal';
import { MultiplePagesDelete } from '../MultipleDeleteModal';
import { RenamePageModal } from '../RenamePageModal';
import { RestorePageModal } from '../RestorePageModal';
import {
  AddIcon,
  ButtonAction,
  ButtonActionContainer,
  CardButton,
  CardContainer,
  CardContent,
  CardExtraContent,
  CardName,
  CardsContainer,
  DropdownButtonContainer,
  LoadingCoursePagesCards,
  LoadingCoursePagesCardsContainer,
  MultipleDeleteIcon,
  PagesSection,
  PagesSectionHeader,
  PagesSectionHeaderBottomContainer,
  PagesSectionHeaderText,
  PagesSectionHeaderTopContainer,
  PagesSectionPagesFoundText,
} from './styles';

export interface ICoursePagesProps {
  dataCy?: string;
  disabled?: boolean;
  isAllowedToEdit?: boolean;
  isDropdownOpen?: boolean;
  loading?: boolean;
  handlerUpdatePageFromEditor: (index: number) => {
    newCoursePages: ICoursePage[];
    index: number;
  } | null;
  onAddPage?: (newPage: ICoursePage, newSelectPosition: number) => void;
  onChangePagesOrder?: (from: number, to: number) => void;
  onDeletePage?: (deletedInfo: IDeleteCoursePageInfo) => void;
  onDropdownOpenChange?: (open: boolean) => void;
  onRenamePage?: (index: number, newTitle: string) => void;
  onRestorePage?: (index: number, historyPage: IHistoryPage) => void;
  onSelectPage?: (
    index: number,
    oldIndex: number,
    updateEditor?: boolean
  ) => void;
  onSaveModifications: (pages: ICoursePage[]) => Promise<void>;
  onDeletedMultiplePages?: (pages: number[]) => Promise<void>;
}

const DroppableId = 'course-pages';

export const CoursePages = ({
  dataCy = 'list-pages-container',
  disabled = false,
  isAllowedToEdit = true,
  isDropdownOpen = false,
  loading = false,
  handlerUpdatePageFromEditor,
  onAddPage,
  onChangePagesOrder,
  onDeletePage,
  onDropdownOpenChange,
  onRenamePage,
  onRestorePage,
  onSelectPage,
  onSaveModifications,
  onDeletedMultiplePages,
}: ICoursePagesProps): JSX.Element => {
  const { addToast } = useToast();
  const { addDragDropListener, removeDragDropListener } = useDragDrop();

  const [isColorsMatchModalOpen, setIsColorsMatchModalOpen] = useState(false);
  const [templateColors, setTemplateColors] = useState<IMatchTemplateColors>();

  const [loadingMatchColors, setLoadingMatchColors] = useState(false);

  const { companyId = '' } = useParams();
  const companyIdNum = useMemo(() => parseInt(companyId), [companyId]);
  // const [hasFeedback, setHasFeedback] = useState(false);
  const [isModalAddPageOpen, setIsModalAddPageOpen] = useState(false);
  const [pageIndexToDelete, setPageIndexToDelete] = useState(NaN);
  const isModalDeletePageOpen = pageIndexToDelete >= 0;

  const [pageIndexToRename, setPageIndexToRename] = useState(NaN);
  const isModalRenamePageOpen = pageIndexToRename >= 0;

  const [pageIndexToRestore, setPageIndexToRestore] = useState(NaN);
  const isModalRestorePageOpen = pageIndexToRestore >= 0;

  const [isModalMultipleDeletePageOpen, setIsModalMultipleDeletePageOpen] =
    useState(false);

  const [isSearchingPages, setIsSearchingPages] = useState<boolean>(false);
  const [newPageId, setNewPageId] = useState<number>(0);
  const [searchPageValue, setSearchPageValue] = useState<string>('');
  const [filteredCoursePagesMap, setFilteredCoursePagesMap] = useState<
    Map<number, ICoursePage>
  >(new Map());

  const {
    addCoursePage,
    changeCoursePagesOrder,
    changeCoursePageContent,
    coursePages,
    deleteCoursePage,
    selectedCoursePageIndex,
    selectCoursePageIndex,
    getPageWithIndex,
  } = useCoursePages();

  const { selectedCourse } = useCourse();

  const addPageTo = useCallback(
    (to: number, newPage: ICoursePage) => {
      if (to < 0 || to > coursePages.length) {
        return;
      }

      addCoursePage(newPage, to);

      if (
        newPage.templateColorsFound &&
        selectedCourse?.globalStyle?.primaryColor !== null
      ) {
        setIsColorsMatchModalOpen(true);
        setTemplateColors(newPage.templateColorsFound);
        setNewPageId(newPage.id);
      }

      onAddPage?.(newPage, to);
    },
    [
      addCoursePage,
      coursePages.length,
      onAddPage,
      selectedCourse?.globalStyle?.primaryColor,
    ]
  );

  const handleOpenAddPageModal = useCallback(() => {
    setIsModalAddPageOpen(true);
  }, []);

  const handleOpenMultipleDeletePage = useCallback(() => {
    setIsModalMultipleDeletePageOpen(true);
  }, []);

  const handleDeclineAddPage = useCallback(() => {
    setIsModalAddPageOpen(false);
  }, []);

  const handleDuplicatePage = useCallback(
    (e: MouseEvent<HTMLDivElement>, index: number) => {
      e.stopPropagation();

      // TODO: Gabriel Cardoso - Preciso ajustar isso aqui
      // const resultUpdate = handlerUpdatePageFromEditor(index);
      // if (!resultUpdate) {
      //   addToast({
      //     title: 'Error on duplicate page',
      //     description:
      //       'An error occurred when getting the page. Please try again or contact Platform support.',
      //     styleType: 'error',
      //     dataCy: 'duplicate-page-error-toast',
      //   });
      //   return;
      // }

      // const { newCoursePages } = resultUpdate;
      // const courseReference = newCoursePages.at(index);

      // if (!courseReference) {
      //   addToast({
      //     title: 'Error on duplicate page',
      //     description:
      //       'An error occurred when handle the page. Please try again or contact Platform support.',
      //     styleType: 'error',
      //     dataCy: 'duplicate-page-error-toast',
      //   });
      //   return;
      // }

      const pageBase = getPageWithIndex(index);
      if (!pageBase) {
        addToast({
          title: 'Error on duplicate page',
          description:
            'An error occurred when handle the page. Please try again or contact Platform support.',
          styleType: 'error',
          dataCy: 'duplicate-page-error-toast',
        });
        return;
      }
      const nextIndex = index + 1;
      const tempId = generateNegativeTimestamp();
      const newPage: ICoursePage = {
        ...pageBase,
        id: tempId,
        title: pageBase.title + '(copy)',
        pageNumber: nextIndex + 1,
        isNew: true,
        createdAt: '',
        updatedAt: '',
      };
      addPageTo(nextIndex, newPage);
    },
    [addPageTo, addToast, getPageWithIndex]
  );

  const handleSelectPage = useCallback(
    (index: number, updateEditor = true) => {
      if (
        index < 0 ||
        selectedCoursePageIndex === index ||
        index >= coursePages.length
      ) {
        return;
      }

      onSelectPage?.(index, selectedCoursePageIndex, updateEditor);

      selectCoursePageIndex(index);
    },
    [
      coursePages.length,
      onSelectPage,
      selectCoursePageIndex,
      selectedCoursePageIndex,
    ]
  );

  const handleOpenModalRestorePage = useCallback(
    (e: MouseEvent<HTMLDivElement>, index: number) => {
      e.stopPropagation();
      setPageIndexToRestore(index);
    },
    []
  );

  const handleRestorePage = useCallback(
    (historyPage: IHistoryPage) => {
      changeCoursePageContent(pageIndexToRestore, {
        htmlContent: historyPage.page.htmlContent,
        cssContent: historyPage.page.cssContent,
        components: historyPage.page.components,
      });
      onRestorePage?.(pageIndexToRestore, historyPage);

      setPageIndexToRestore(NaN);
    },
    [changeCoursePageContent, onRestorePage, pageIndexToRestore]
  );

  const handleDeclineRestorePage = useCallback(() => {
    setPageIndexToRestore(NaN);
  }, []);

  const handleOpenModalRenamePage = useCallback(
    (e: MouseEvent<HTMLDivElement>, index: number) => {
      e.stopPropagation();
      setPageIndexToRename(index);
    },
    []
  );

  const handleRenamePage = useCallback(
    (newTitle: string) => {
      if (
        !newTitle ||
        pageIndexToRename < 0 ||
        pageIndexToRename >= coursePages.length
      ) {
        return;
      }

      changeCoursePageContent(pageIndexToRename, { title: newTitle });
      onRenamePage?.(pageIndexToRename, newTitle);

      setPageIndexToRename(NaN);
    },
    [
      changeCoursePageContent,
      coursePages.length,
      onRenamePage,
      pageIndexToRename,
    ]
  );

  const handleDeclineRenamePage = useCallback(() => {
    setPageIndexToRename(NaN);
  }, []);

  const selectPageAfterRemove = useCallback(
    (deletedInfo: IDeleteCoursePageInfo) => {
      const { newCoursePages, indexRemoved } = deletedInfo;
      const currentLength = newCoursePages.length;
      if (indexRemoved < 0 || indexRemoved > currentLength) {
        return;
      }

      if (currentLength === 0) {
        selectCoursePageIndex(-1);
      } else if (indexRemoved === selectedCoursePageIndex) {
        const deletingTheLastItem = indexRemoved === currentLength;
        const indexToSelect = deletingTheLastItem
          ? indexRemoved - 1
          : indexRemoved;

        deletedInfo.indexToSelected = indexToSelect;
        selectCoursePageIndex(indexToSelect);
        onDeletePage?.(deletedInfo);

        return;
      } else if (indexRemoved < selectedCoursePageIndex) {
        selectCoursePageIndex(selectedCoursePageIndex - 1);
      }
      onDeletePage?.(deletedInfo);
    },
    [onDeletePage, selectCoursePageIndex, selectedCoursePageIndex]
  );

  const handleOpenModalDeletePage = useCallback(
    (e: MouseEvent<HTMLDivElement>, index: number) => {
      e.stopPropagation();
      setPageIndexToDelete(index);
    },
    []
  );

  const handleDeletePage = useCallback(
    async (index: number) => {
      try {
        const deletedInfo = await deleteCoursePage(index);
        selectPageAfterRemove(deletedInfo);
        if (deletedInfo.idRemoved > 0) {
          addToast({
            title: 'Page deleted',
            description: 'The page was deleted successfully',
            styleType: 'success',
            dataCy: 'delete-page-success-toast',
          });
        }
      } catch (error) {
        addToast({
          title: 'Error on delete page',
          description:
            'Ops, something happens when try to delete page, please try again later',
          styleType: 'error',
          dataCy: 'delete-page-error-toast',
        });
      } finally {
        setPageIndexToDelete(NaN);
      }
    },
    [deleteCoursePage, selectPageAfterRemove, addToast]
  );

  const handleDeleteMultiplePages = useCallback(
    async (pages: number[]) => {
      await onDeletedMultiplePages?.(pages);
    },
    [onDeletedMultiplePages]
  );

  const handleDeclineDeletePage = useCallback(() => {
    setPageIndexToDelete(NaN);
  }, []);

  const handleDeclineMultipleDeletePage = useCallback(() => {
    setIsModalMultipleDeletePageOpen(false);
  }, []);

  const handleDeclineEditColorsPage = useCallback(() => {
    setIsColorsMatchModalOpen(false);
  }, []);

  const handleAcceptEditColorsPage = useCallback(
    async (pages: ICoursePage[]) => {
      setLoadingMatchColors(true);
      await onSaveModifications(pages)
        .then(() => {
          setIsColorsMatchModalOpen(false);
          setTemplateColors({});
          addToast({
            title: 'Success',
            description: 'Colors were successfully matched.',
            styleType: 'success',
            dataCy: 'save-match-colors-page-toast',
          });
        })
        .catch(() => {
          addToast({
            title: 'Error',
            description: 'An error occurred when trying to save the colors.',
            styleType: 'error',
            dataCy: 'save-match-colors-page-error-toast',
          });
        })
        .finally(() => {
          setLoadingMatchColors(false);
        });
    },
    [onSaveModifications, addToast]
  );

  const changePagesOrder = useCallback(
    (from: number, to: number) => {
      if (to < 0 || to === from || to >= coursePages.length) {
        return;
      }

      if (from === selectedCoursePageIndex) {
        handleSelectPage(to, false);
      } else if (
        from > selectedCoursePageIndex &&
        to <= selectedCoursePageIndex
      ) {
        // the selectedPage will be offset
        handleSelectPage(selectedCoursePageIndex + 1, false);
      } else if (
        from < selectedCoursePageIndex &&
        to >= selectedCoursePageIndex
      ) {
        // the selectedPage will be offset
        handleSelectPage(selectedCoursePageIndex - 1, false);
      }

      changeCoursePagesOrder?.(from, to);
      onChangePagesOrder?.(from, to);
    },
    [
      changeCoursePagesOrder,
      coursePages.length,
      handleSelectPage,
      onChangePagesOrder,
      selectedCoursePageIndex,
    ]
  );

  const handleAddPage = useCallback(
    (page: ICoursePageDTO) => {
      const nextPosition = selectedCoursePageIndex + 1;
      const isNotTemplate = page.id < 0;
      const newPage: ICoursePage = {
        ...page,
        pageNumber: nextPosition,
        isNew: isNotTemplate,
        isNewFromTemplate: !isNotTemplate,
      };

      addPageTo(nextPosition, newPage);
      setIsModalAddPageOpen(false);
    },
    [addPageTo, selectedCoursePageIndex]
  );

  useEffect(() => {
    if (disabled) return;
    const handleValueChange: IDragDropHandler = ({ droppableId, from, to }) => {
      if (droppableId !== DroppableId) return;
      changePagesOrder(from, to);
    };

    addDragDropListener(DroppableId, handleValueChange);

    return () => {
      removeDragDropListener(DroppableId);
    };
  }, [addDragDropListener, changePagesOrder, disabled, removeDragDropListener]);

  const debouncedSearch = useRef(
    debounce(
      (currentSearchPageValue: string, currentCoursePages: ICoursePage[]) => {
        if (currentSearchPageValue === '') {
          setFilteredCoursePagesMap(new Map());
          return;
        }

        const filteredPagesMap = new Map<number, ICoursePage>();
        currentCoursePages.forEach((page) => {
          if (
            page.title
              .toLowerCase()
              .includes(currentSearchPageValue.toLowerCase())
          ) {
            filteredPagesMap.set(page.id, page);
          }
        });

        setIsSearchingPages(true);
        setFilteredCoursePagesMap(filteredPagesMap);
      },
      1000
    )
  ).current;

  useEffect(() => {
    if (searchPageValue === '') {
      setFilteredCoursePagesMap(new Map());
      setIsSearchingPages(false);
    }

    debouncedSearch(searchPageValue, coursePages);
  }, [coursePages, searchPageValue, debouncedSearch]);

  return (
    <PagesSection data-cy={dataCy}>
      <PagesSectionHeader data-cy="header-list">
        <PagesSectionHeaderTopContainer>
          <PagesSectionHeaderText data-cy="text-pageSection">
            Pages
          </PagesSectionHeaderText>
          {isAllowedToEdit && (
            <ButtonActionContainer>
              <ButtonAction
                disabled={disabled}
                data-cy="button-multipleDelete"
                onClick={handleOpenMultipleDeletePage}
              >
                <MultipleDeleteIcon />
              </ButtonAction>
              <ButtonAction
                disabled={disabled}
                data-cy="button-addPage"
                onClick={handleOpenAddPageModal}
              >
                <AddIcon />
              </ButtonAction>
            </ButtonActionContainer>
          )}
        </PagesSectionHeaderTopContainer>
        <PagesSectionHeaderBottomContainer>
          <SearchPageInput
            id="search-pages"
            data-cy="search-pageInput"
            value={searchPageValue}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setSearchPageValue(e.target.value);
              }
            }}
            placeholder="Search pages"
            type="text"
          />
          {searchPageValue !== '' &&
            // filteredCoursePagesMap.size > 0 &&
            isSearchingPages && (
              <PagesSectionPagesFoundText data-cy="text-header-pages-found-editCourse">
                {filteredCoursePagesMap.size}{' '}
                {filteredCoursePagesMap.size > 1 ||
                filteredCoursePagesMap.size === 0
                  ? 'pages'
                  : 'page'}{' '}
                found
              </PagesSectionPagesFoundText>
            )}
        </PagesSectionHeaderBottomContainer>
      </PagesSectionHeader>
      <CardsContainer data-cy="cards-section">
        {loading ? (
          <LoadingCoursePagesCardsContainer data-cy="loading-course-pages-cards-container">
            <LoadingCoursePagesCards dataCy="loading-course-pages-cards" />
          </LoadingCoursePagesCardsContainer>
        ) : (
          <>
            <Droppable
              data-cy="droppable-cards-container"
              isDropDisabled={!isAllowedToEdit || disabled}
              droppableId={DroppableId}
            >
              {(provided) => (
                <CardContainer
                  data-cy="card-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {coursePages.map(({ id, title }, index) => (
                    <Card
                      data-cy={id}
                      draggableId={id}
                      index={index}
                      key={id}
                      draggable={!disabled}
                    >
                      <CardButton
                        key={id}
                        data-cy={`${title}`}
                        disabled={disabled}
                        onClick={() => {
                          if (!disabled) handleSelectPage(index);
                        }}
                        selected={selectedCoursePageIndex === index}
                        highlighted={filteredCoursePagesMap.has(id)}
                      >
                        <BaseTooltip message={title}>
                          <CardContent
                            data-cy="card-content"
                            style={{
                              maxWidth: isAllowedToEdit ? '8vw' : '14vw',
                            }}
                          >
                            <CardName data-cy={title}>{title}</CardName>
                          </CardContent>
                        </BaseTooltip>
                        {isAllowedToEdit && (
                          <CardExtraContent data-cy="card-extra-content">
                            <DropdownButtonContainer data-cy="dropdown-container">
                              <BaseDropdown
                                dataCy={`dropdown-menu-button-${title}`}
                                onOpenChange={onDropdownOpenChange}
                                zIndex={2}
                              >
                                <DropdownItem
                                  disabled={disabled}
                                  data-cy={`rename-${title}`}
                                  onClick={(e) => {
                                    handleOpenModalRenamePage(e, index);
                                  }}
                                >
                                  Rename
                                </DropdownItem>
                                <DropdownItem
                                  disabled={disabled}
                                  data-cy={`duplicate-${title}`}
                                  onClick={(e) => {
                                    handleDuplicatePage(e, index);
                                  }}
                                >
                                  Duplicate
                                </DropdownItem>
                                <DropdownItem
                                  disabled={disabled}
                                  data-cy={`delete-${title}`}
                                  onClick={(e) => {
                                    handleOpenModalDeletePage(e, index);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                                <DropdownItem
                                  data-cy={`restore-${title}`}
                                  onClick={(e) => {
                                    handleOpenModalRestorePage(e, index);
                                  }}
                                >
                                  Restore
                                </DropdownItem>
                              </BaseDropdown>
                            </DropdownButtonContainer>
                          </CardExtraContent>
                        )}
                      </CardButton>
                    </Card>
                  ))}

                  {provided.placeholder}
                </CardContainer>
              )}
            </Droppable>
          </>
        )}
      </CardsContainer>

      <AddPageModal
        companyId={companyIdNum}
        onAccept={handleAddPage}
        onDecline={handleDeclineAddPage}
        open={isModalAddPageOpen}
        waitToOpen={isDropdownOpen}
        zIndex={10}
      />

      <RenamePageModal
        onAccept={handleRenamePage}
        onDecline={handleDeclineRenamePage}
        open={isModalRenamePageOpen}
        pageTitle={coursePages[pageIndexToRename]?.title}
        waitToOpen={isDropdownOpen}
        zIndex={10}
      />
      <RestorePageModal
        onAccept={handleRestorePage}
        onDecline={handleDeclineRestorePage}
        open={isModalRestorePageOpen}
        coursePage={coursePages[pageIndexToRestore]}
        waitToOpen={isDropdownOpen}
        zIndex={10}
      />
      <DeletePageModal
        onAccept={handleDeletePage}
        onDecline={handleDeclineDeletePage}
        open={isModalDeletePageOpen}
        pageIndexToDelete={pageIndexToDelete}
        pageTitle={coursePages[pageIndexToDelete]?.title}
        waitToOpen={isDropdownOpen}
        zIndex={10}
      />

      <MultiplePagesDelete
        coursePages={coursePages}
        onAccept={handleDeleteMultiplePages}
        onDecline={handleDeclineMultipleDeletePage}
        open={isModalMultipleDeletePageOpen}
        waitToOpen={isDropdownOpen}
        loading={loading}
        zIndex={10}
      />

      {templateColors && (
        <MatchSvgTemplateColorsModal
          onDecline={handleDeclineEditColorsPage}
          onAccept={handleAcceptEditColorsPage}
          pageId={newPageId}
          open={isColorsMatchModalOpen}
          loading={loadingMatchColors}
          colorsDefault={templateColors}
          zIndex={10}
        />
      )}
    </PagesSection>
  );
};
