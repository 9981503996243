import { useCallback, useEffect, useState } from 'react';

import { type IFormModalProps } from '@gbs-monorepo-packages/common';

import { ColorPickerSelectData } from '../../../../components/ColorPickerSelectData';
import { type ICoursePage } from '../../../../contexts/coursePage';
import { useCourse } from '../../../../hooks/useCourse';
import { type IMatchTemplateColors } from '../../../../services/templates';
import { ColorPickerContainer, Container, FormModalCustom } from './styles';

interface IMatchSvgTemplateColorsModalProps
  extends Partial<
    Omit<IFormModalProps, 'children' | 'onAccept' | 'onOpenChange'>
  > {
  loading: boolean;
  colorsDefault: IMatchTemplateColors;
  onAccept: (pages: ICoursePage[]) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
  pageId: number;
}

export const MatchSvgTemplateColorsModal = ({
  loading,
  colorsDefault,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  pageId,
  ...props
}: IMatchSvgTemplateColorsModalProps): JSX.Element | null => {
  const { selectedCourse } = useCourse();
  const [templateColors, setTemplateColors] =
    useState<IMatchTemplateColors>(colorsDefault);

  useEffect(() => {
    const transformedObject: IMatchTemplateColors = {
      ...colorsDefault,
    };

    setTemplateColors(transformedObject);
  }, [colorsDefault]);

  const handleDecline = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  const handleAccept = useCallback(async () => {
    const selectedCourseAux = selectedCourse;
    const coursePagesAux = selectedCourseAux?.pages;
    const updatedCoursePagesAux = coursePagesAux?.map((page) => {
      if (page.id === pageId) {
        return {
          ...page,
          templateColorsFound: templateColors,
        };
      } else {
        return page;
      }
    });

    if (updatedCoursePagesAux) {
      await onAccept(updatedCoursePagesAux);
    }
  }, [templateColors, onAccept, pageId, selectedCourse]);

  return !open ? null : (
    <FormModalCustom
      hiddenCloseButton
      acceptText="Save"
      dataCy="edit-template-colors-dialog-modal"
      mainText="Match Template Styles"
      disabled={false}
      {...props}
      onAccept={() => {
        void handleAccept();
      }}
      onDecline={handleDecline}
      loading={loading}
      open={!waitToOpen}
    >
      <Container>
        {Object.entries(templateColors).map(([colorKey, colorValue], index) => (
          <ColorPickerContainer key={index}>
            <ColorPickerSelectData
              color={String(colorValue)}
              dataCy={`color-picker-${colorKey}`}
              errorMessage=""
              label={`Import color`}
              onChange={(color, colorKey) => {
                if (colorKey) {
                  const newTemplateColors: IMatchTemplateColors = {
                    ...templateColors,
                    [colorKey]: color,
                  };
                  setTemplateColors(newTemplateColors);
                }
              }}
              colorKey={colorKey}
            />
          </ColorPickerContainer>
        ))}
      </Container>
    </FormModalCustom>
  );
};
